@tailwind base;
@tailwind components;
@tailwind utilities;

.slide-up {
    animation: slide-up 0.5s ease-in;
}

@keyframes slide-up {
    0% {
        transform: translateY(100%);
    }
    100% {
        transform: translateY(0);
    }
}

.slide-down {
    animation: slide-down 0.5s ease-out;
}

@keyframes slide-down {
    0% {
        transform: translateY(0);
    }
    100% {
        transform: translateY(100%);
        opacity: 0;
    }
}